import { Box, Chip, Grid, Typography, useMediaQuery } from "@mui/material";
import { themeColors, themeFonts } from "../../../configs";
import { Image1, Image2, Image4 } from "../../../pngs";

export const CuratorsPointOfView = ({
  isMiniScreen,
  stickyTabs,
  isMidiScreen,
}: {
  isMiniScreen?: any;
  stickyTabs?: any;
  isMidiScreen?: any;
}) => {
  const isMediumScreen = useMediaQuery("(max-width:1152px)");
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          paddingLeft: "0 !important",
          paddingTop:
            stickyTabs && isMidiScreen
              ? "50px !important"
              : isMediumScreen
              ? "50px"
              : "80px !important",
          gap: "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMediumScreen ? "column-reverse" : "row",
            flexWrap: "wrap",
            justifyContent: isMediumScreen ? "center" : "space-between",
            gap: isMediumScreen ? "30px" : "115px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <img src={Image1} alt="" className="point-of-view-section-image" />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Chip
              label="Simple playlist addition"
              sx={{
                color: themeColors["#00ADB5"],
                fontSize: "18px",
                fontFamily: themeFonts["Poppins-Medium"],
                background: themeColors["#00ADB526"],
                paddingY: "6px",
                paddingX: "19px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                whiteSpace: "normal",
                height: "fit-content",
                textAlign: "center",
                borderRadius: "50px",
                "& span.MuiChip-label": {
                  whiteSpace: "normal",
                },
              }}
            />
            <Typography
              sx={{
                color: themeColors["#FFFFFF"],
                fontSize: "22px",
                fontFamily: themeFonts["Poppins-SemiBold"],
                maxWidth: 486,
                textAlign: isMiniScreen ? "center" : "justify",
              }}
            >
              Easily submit your Spotify playlists, choose your genres, and wait
              for new tracks to provide feedback on!
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMediumScreen ? "column" : "row",
            flexWrap: "wrap",
            justifyContent: isMediumScreen ? "center" : "space-between",
            gap: isMediumScreen ? "30px" : "115px",
            width: "100%",
            alignItems: "center",
            marginTop: isMediumScreen ? "60px" : "0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Chip
              label="Curator dashboard"
              sx={{
                color: themeColors["#00ADB5"],
                fontSize: "18px",
                fontFamily: themeFonts["Poppins-Medium"],
                background: themeColors["#00ADB526"],
                paddingY: "6px",
                paddingX: "19px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                whiteSpace: "normal",
                height: "fit-content",
                textAlign: "center",
                borderRadius: "50px",
                "& span.MuiChip-label": {
                  whiteSpace: "normal",
                },
              }}
            />
            <Typography
              sx={{
                color: themeColors["#FFFFFF"],
                fontSize: "22px",
                fontFamily: themeFonts["Poppins-SemiBold"],
                maxWidth: 486,
                textAlign: isMiniScreen ? "center" : "justify",
              }}
            >
              Review submitted tracks with "Accept" or "Decline" options and
              monitor your activity as an independent curator, all within your
              PitchPlaylists dashboard.
            </Typography>
          </Box>
          <img src={Image2} alt="" className="point-of-view-section-image" />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMediumScreen ? "column-reverse" : "row",
            flexWrap: "wrap",
            justifyContent: isMediumScreen ? "center" : "space-between",
            gap: isMediumScreen ? "30px" : "115px",
            width: "100%",
            alignItems: "center",
            marginTop: isMediumScreen ? "60px" : "0px",
          }}
        >
          <img src={Image4} alt="" className="point-of-view-section-image" />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Chip
              label="Compare your curator activity with others on the platform"
              sx={{
                color: themeColors["#00ADB5"],
                fontSize: "18px",
                fontFamily: themeFonts["Poppins-Medium"],
                background: themeColors["#00ADB526"],
                paddingY: "6px",
                paddingX: "19px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                whiteSpace: "normal",
                height: "fit-content",
                textAlign: "center",
                borderRadius: "50px",
                "& span.MuiChip-label": {
                  whiteSpace: "normal",
                },
              }}
            />
            <Typography
              sx={{
                color: themeColors["#FFFFFF"],
                fontSize: "22px",
                fontFamily: themeFonts["Poppins-SemiBold"],
                maxWidth: 531,
                textAlign: isMiniScreen ? "center" : "justify",
              }}
            >
              Check how your activity as a curator measures up against others on
              the platform. Get insights to help you improve your playlist
              curation!
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
