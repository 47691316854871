import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  useMediaQuery,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  Link as MuiLink,
} from "@mui/material";
import React, { useState } from "react";
import { themeFonts } from "../../configs";
import {
  InformationIcon,
  Search2Icon,
  SearchGreyIcon,
  ThumbsUpIcon,
} from "../../svgs";
import { useGetPlayListByIdQuery } from "../../components/apis/playlist";
import { PlayListTrack } from "./playlist-tract";
import { ROUTES } from "../../components/consts/routes.consts";
import { Link, useNavigate } from "react-router-dom";
import { useGetMeInfoQuery } from "../../components/apis/userDetailsAPi";
import { SpotifyGreenLogo } from "../../pngs";
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: "0px !important",
    marginBottom: "18px !important",
    boxShadow: "10px 10px 20px 0px #0000001A",
    border: "0.5px solid #00000033 !important",
    background: "#FFFFFF",
    color: "#000000",
    textAlign: "center",
    width: "267px",
    borderRadius: "0px",
    padding: "10px 10px 16px 10px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF",
    fontSize: "30px",
  },
}));
export const SubmittedPlaylistPage = () => {
  const isMiniScreen = useMediaQuery("(max-width:500px)");
  const userId = localStorage.getItem("userId");
  const id = localStorage.getItem("id");
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const { data, refetch } = useGetPlayListByIdQuery({ userId });
  const { data: userInfo, refetch: userInfoRefetch } = useGetMeInfoQuery<any>({
    id,
  });
  const responseRateString = userInfo?.responseRate?.$numberDecimal ?? "0";
  const responseRateDecimal = parseFloat(responseRateString);
  const responseRatePercentage = (responseRateDecimal * 100).toFixed(2);

  const userName = localStorage.getItem("userName");
  const filteredPlaylistTracks =
    data &&
    data?.userPlaylist?.filter((track: any) =>
      track?.playlistName.toLowerCase().includes(searchValue?.toLowerCase())
    );
  const pendingTracks =
    filteredPlaylistTracks &&
    filteredPlaylistTracks?.flatMap(
      (playlistItem: { submittedTracks: any[] }) =>
        playlistItem.submittedTracks.filter(
          (track) => track.status === "pending"
        )
    );
  const tracks =
    data &&
    data?.userPlaylist?.flatMap((playlistItem: { submittedTracks: any[] }) =>
      playlistItem.submittedTracks.filter((track) => track.status === "pending")
    );
  const uniquePlaylists: any = new Set(
    tracks?.map((track: { playlist: any }) => track.playlist)
  );
  const totalUniquePlaylists = uniquePlaylists.size;

  const noSubmission =
    filteredPlaylistTracks &&
    filteredPlaylistTracks?.flatMap(
      (playlistItem: { submittedTracks: any[] }) => playlistItem.submittedTracks
    );
  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };
  // const truncateString = (str: any, num: any) => {
  //   if (str?.length <= num) {
  //     return str;
  //   }
  //   return str?.slice(0, num) + "...";
  // };
  return (
    <Grid
      container
      spacing={3}
      sx={{
        margin: "0px !important",
        flexDirection: "column",
        width: "100% !important",
        padding: "0 !important ",
      }}
    >
      <Grid
        item
        xs={11}
        sx={{
          width: "100%",
          padding: "32px 0",
          display: "flex",
          flexDirection: "column",
          alignItems: isMiniScreen ? "center" : "unset",
        }}
      >
        <Box
          className="flex-direction-column-sm"
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Typography
            className="font-23-sm"
            sx={{
              fontSize: "32px",
              fontWeight: "600",
              color: "#000",
            }}
          >
            Hello, {userName}
          </Typography>
          <Button
            className="margin-0 width-fit-content"
            onClick={() => {
              navigate(ROUTES.SUBMIT_A_PLAYLIST);
            }}
            variant="contained"
            sx={{
              marginLeft: "auto",
              padding: "11px 20px",
              backgroundColor: "#00ADB5",
              color: "#fff",
              borderRadius: "33px",
              height: "auto",
              "&:hover": {
                background: " #00ADB5",
              },
            }}
          >
            Add new playlist
          </Button>
        </Box>
        <Box
          className=" flex-direction-column-xsm align-items-start-sm"
          sx={{
            display: "flex",
            marginTop: "24px",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#000",
              width: isMiniScreen ? "100%" : "unset",
              textAlign: "center",
            }}
          >
            Received Submissions
          </Typography>
          {totalUniquePlaylists > 1 && (
            <TextField
              type="search"
              onChange={handleSearchChange}
              placeholder="Search By Playlist Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchGreyIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                marginLeft: "auto",
                height: "auto",
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "revert-layer",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#00000080",
                  fontSize: "15px!important",
                  padding: "7px 30px 7px 0px",
                  "&::placeholder": {
                    color: "#00000080",
                    fontSize: "15px!important",
                    zIndex: "999",
                    position: "absolute",
                  },
                },
              }}
            />
          )}
        </Box>
        {filteredPlaylistTracks?.length !== 0 && (
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              marginTop: "24px",
              flexWrap: "wrap",
              justifyContent: isMiniScreen ? "center" : "unset",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#00ADB5",
                padding: "11px 16px",
                border: "1px solid #00ADB5",
                borderRadius: "5px",
              }}
            >
              Your Response Rate: {responseRatePercentage}%
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#000000",
                  fontSize: "11px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                Last 30 days
              </Typography>
            </Typography>

            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#00ADB5",
                padding: "11px 16px",
                border: "1px solid #00ADB5",
                borderRadius: "5px",
              }}
            >
              <Box sx={{ display: "flex ", gap: "4px", alignItems: "center" }}>
                Bonus Points: {userInfo?.bouncePoint}
                <HtmlTooltip
                  title={
                    <Typography
                      sx={{
                        margin: "0px",
                        fontFamily: themeFonts["Poppins-Medium"],
                        fontSize: "13px",
                        color: "#000000",
                        lineHeight: "19.5px",
                        textTransform: "capitalize",
                      }}
                    >
                      When the curator you invite adds at least one playlist to our website and provides feedback on the first song from their dashboard, you'll earn bonus points, making you more active and climbing to a higher position on our top chart!
                    </Typography>
                  }
                  placement="top"
                  arrow
                >
                  <Typography
                    style={{
                      padding: "0px",
                      margin: "0px",
                      lineHeight: "23px",
                      marginLeft: "3px",
                    }}
                  >
                    <InformationIcon />
                  </Typography>
                </HtmlTooltip>
              </Box>

              <Typography
                sx={{
                  textAlign: "center",
                  color: "#000000",
                  fontSize: "11px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                Last 30 days
              </Typography>
            </Typography>
          </Box>
        )}
        {filteredPlaylistTracks?.length === 0 ? (
          <Box
            sx={{
              backgroundColor: "#F0F0F0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "33px",
              textAlign: "center",
              height: "330px",
            }}
          >
            <Box>
              <Search2Icon />
              <Typography
                sx={{
                  fontSize: "32px",
                  fontWeight: "600",
                  color: "#000000",
                }}
              >
                Add New Playlist
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#00000080",
                }}
              >
                Simply click on the "Add New Playlist" button above
              </Typography>
            </Box>
          </Box>
        ) : pendingTracks?.length === 0 ? (
          <Box
            sx={{
              backgroundColor: "#F0F0F0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "33px",
              textAlign: "center",
              height: "330px",
              width: "100%",
            }}
          >
            <Box>
              <ThumbsUpIcon height={55} width={61} />
              <Typography
                sx={{
                  fontSize: isMiniScreen ? " 16px" : "32px",
                  fontWeight: "600",
                  color: "#000000",
                }}
              >
                You’re all up to date!
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#00000080",
                }}
              >
                Check Back Later
              </Typography>
            </Box>
          </Box>
        ) : noSubmission?.length === 0 ? (
          <Box
            sx={{
              backgroundColor: "#F0F0F0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "33px",
              textAlign: "center",
              height: "330px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "32px",
                  fontWeight: "600",
                  color: "#000000",
                }}
              >
                0 Submissions
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#00000080",
                  marginTop: "8px",
                }}
              >
                You haven’t submitted a song to playlist yet
              </Typography>

              <Button
                onClick={() => {
                  navigate(ROUTES.SUBMIT_A_SONG);
                }}
                sx={{
                  marginTop: "24px",
                  padding: "11px 20px",
                  backgroundColor: "#00ADB5",
                  color: "#fff",
                  borderRadius: "33px",
                  height: "auto",
                }}
              >
                Submit a song
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            {filteredPlaylistTracks &&
              filteredPlaylistTracks.map((playlistItem: any, index: any) => {
                const pendingTracks = playlistItem?.submittedTracks.filter(
                  (track: any) => track.status === "pending"
                );

                return (
                  <>
                    {playlistItem && pendingTracks?.length > 0 && (
                      <>
                        <Box
                          key={index}
                          sx={{
                            marginTop: "32px",
                            display: "flex",
                            alignItems: "center",
                            gap: "18px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100px",
                              height: "100px",
                            }}
                          >
                            <img
                              src={playlistItem.imageUrl}
                              alt=""
                              className="img-fluid"
                            />
                          </Box>

                          <Box>
                            <Box
                              sx={{
                                height: "54px",
                                width: "117px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={SpotifyGreenLogo}
                                alt=""
                                style={{ width: "90px", height: "27px" }}
                              />
                            </Box>
                            <Box
                              sx={{
                                paddingLeft: "13.5px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: themeFonts["Poppins-Bold"],
                                  lineHeight: "24px",
                                  color: "#000000",
                                }}
                              >
                                Received Submissions For Playlist:
                              </Typography>
                              <MuiLink
                                component={Link}
                                to={playlistItem?.playlistUrl}
                                target="blank"
                                rel="noopener noreferrer"
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: themeFonts["Poppins-Bold"],
                                  lineHeight: "24px",
                                  color: "#00ADB5",
                                  display: "-webkit-box",
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  wordWrap: "break-word",
                                  textDecoration: "none",
                                  "&:hover": {
                                    textDecoration: "underline",
                                  },
                                }}
                              >
                                {playlistItem?.playlistName}
                              </MuiLink>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            maxWidth: "1350px",
                            width: "100%",
                            backgroundColor: "#D9D9D95C",
                            padding: "24px",
                            margin: "24px  0",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "55px",
                              flexWrap: "wrap",
                            }}
                          >
                            {playlistItem?.submittedTracks?.map(
                              (trackItem: any, trackIndex: any) => {
                                return (
                                  trackItem.status === "pending" && (
                                    <PlayListTrack
                                      playlistId={playlistItem.playlistId}
                                      uri={trackItem.track.uri}
                                      trackItem={trackItem}
                                      refetch={refetch}
                                      userInfoRefetch={userInfoRefetch}
                                      playlistItem={playlistItem}
                                    />
                                  )
                                );
                              }
                            )}
                          </Box>
                        </Box>
                      </>
                    )}
                  </>
                );
              })}
          </>
        )}
      </Grid>
    </Grid>
  );
};
