import {
  Box,
  Grid,
  Typography,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  Link as MuiLink,
  CircularProgress,
} from "@mui/material";
import { LogBackground4, SpotifyGreenLogo } from "../../pngs";
import Button from "@mui/material/Button";
import { EditGenresIcon, ThumbsUpIcon } from "../../svgs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../components/consts/routes.consts";
import { themeColors, themeFonts } from "../../configs";
import {
  useCreatePlaylistMutation,
  useGetPlayListByIdQuery,
} from "../../components/apis/playlist";
import { toast } from "react-toastify";
import GenresData from "../../components/modals/genres-data";
import React, { useState } from "react";
import { EditGatesDialog } from "../../components/modals/edit-gates-modal";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: "0px !important",
    marginBottom: "20px !important",
    border: "0.5px solid #00000033 !important",
    boxShadow: "10px 10px 20px 0px #0000001A",
    background: "#972C2C",
    textAlign: "center",
    width: "171px",
    borderRadius: "0px",
    padding: "11.4px 14px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#972C2C",
    fontSize: "22px",
  },
}));

export const ConfirmPlaylistPage = () => {
  const [open, setOpen] = React.useState(false);
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const [isOpen, setIsOpen] = React.useState(false);
  const [isEditGatesOpen, setIsEditGatesOpen] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState<any[]>([]);
  const [platformType, setPlatformType] = useState("Spotify");
  const [spotifyAccountLink, setSpotifyAccountLink] = useState("");
  const [spotifyPlaylistLink, setSpotifyPlaylistLink] = useState("");
  const [spotifySongLink, setSpotifySongLink] = useState("");
  const [areGenresSelected, setAreGenresSelected] = useState(false);
  // const handleClickEditGatesOpen = () => {
  //   setIsEditGatesOpen(true);
  // };
  const handleClickEditGatesClose = () => {
    setIsEditGatesOpen(false);
  };
  const handleClickOpen = () => {
    setIsOpen(true);
  };
  const handleCloseData = () => {
    setIsOpen(false);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const { playlistData } = location.state || {};
  const [addPlaylist, { isLoading }] = useCreatePlaylistMutation();

  const id = localStorage.getItem("userId");
  const { refetch, isFetching } = useGetPlayListByIdQuery({
    userId: id,
  });
  const errors =
    playlistData?.followers === false ||
    playlistData?.isUserPlaylist === false ||
    playlistData?.playlistAlreadyExist === true ||
    playlistData?.track === false;
  const handleSubmit = async () => {
    if (errors) {
      navigate(ROUTES.SUBMIT_A_PLAYLIST);
    } else {
      try {
        await addPlaylist({
          playlistId: playlistData?.playlist.playlistId,
          genres: selectedGenres,
          submitConditions: {
            followUserUrls: spotifyAccountLink,
            followPlaylistUrls: spotifyPlaylistLink,
            platformType: platformType,
            saveTrackUrl: spotifySongLink,
          },
        }).unwrap();
        // Await the refetch and check its result
        const refetchResult = await refetch();

        if (refetchResult.error) {
          throw new Error("Refetch failed");
        }

        // If refetch is successful and not loading
        if (refetchResult.data && !isFetching) {
          navigate(ROUTES.DASHBOARD);
          toast.success("Success");
        }
      } catch (error) {
        toast.error("Failed");
      }
    }
  };

  const handleContinueButtonClick = () => {
    if (selectedGenres.length === 0 && !errors) {
      setAreGenresSelected(false); // Set state to false to show the tooltip
      if (areGenresSelected === false) {
        handleTooltipOpen();
      }
    } else {
      setAreGenresSelected(true); // Set state to true to hide the tooltip
      handleSubmit(); // Proceed with form submission
    }
  };
  return (
    <Grid
      sx={{
        background: `url(${LogBackground4})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        marginTop: "-80px",
      }}
    >
      <Box
        className=" playlist-section  padding-top-100-sm padding-bottom-20vh-sm height-auto-sm"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            maxWidth: "700px",
            width: "100%",
            textAlign: "center",
            padding: "0 15px",
          }}
        >
          <Typography
            sx={{
              fontSize: "36px",
              fontWeight: "600",
              color: "#ffffff",
            }}
          >
            {playlistData?.isUserPlaylist === false
              ? "OOPS"
              : playlistData?.track === false
              ? "OOPS"
              : playlistData?.followers === false
              ? "OOPS"
              : "Your Playlist Info"}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#fff",
              marginBottom: "20px",
            }}
          >
            {playlistData?.isUserPlaylist === false
              ? "Look like this playlist doesn’t belong to you."
              : playlistData?.playlistAlreadyExist === true
              ? "This playlist has already been added."
              : playlistData?.followers === false
              ? "Sorry , a playlist must have 100 or more followers to be submitted."
              : playlistData?.track === false
              ? "Sorry , a playlist must have 30 or more tracks to be submitted."
              : "Select your playlist genres"}
          </Typography>
          <Box
            className="flex-direction-column-small"
            sx={{
              width: "100%",
              background: "#fff",
              padding: "16px",
              display: "flex",
              gap: "16px",
            }}
          >
            <Box
              className="margin-auto-sm"
              sx={{
                width: "144px",
                height: "144px",
              }}
            >
              <img
                src={playlistData?.playlist.imageUrl}
                alt="Album"
                className="img-fluid"
              />
            </Box>
            <Box
              className="flex-direction-column-xsm"
              sx={{
                flex: "1",
                display: "flex",
                gap: "16px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    height: "42px",
                    width: "91px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={SpotifyGreenLogo}
                    alt=""
                    style={{
                      width: "70px",
                      height: "21px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    paddingLeft: "10.5px",
                  }}
                >
                  <Box
                    className="padding-o-sm"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {/* <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#000000",
                        maxWidth: "277px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                      }}
                    >
                      {playlistData?.playlist.playlistName}
                    </Typography> */}
                    <MuiLink
                      component={Link}
                      to={playlistData?.playlist.playlistUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#000000",
                        maxWidth: "277px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                        textDecoration: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {playlistData?.playlist.playlistName}
                    </MuiLink>
                    <Box
                      sx={{
                        display: "flex",
                        gridGap: "3px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: "500",
                          color: "#00ADB5",
                          textTransform: "capitalize",
                          display: "-webkit-box",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textDecoration: "none",
                        }}
                      >
                        (By{" "}
                      </Typography>

                      <MuiLink
                        component={Link}
                        to={playlistData?.playlist.profileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          fontSize: "10px",
                          fontWeight: "500",
                          color: "#00ADB5",
                          textTransform: "capitalize",
                          display: "-webkit-box",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textDecoration: "none",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {playlistData?.playlist.ownerName}
                      </MuiLink>
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: "500",
                          color: "#00ADB5",
                          textTransform: "capitalize",
                          display: "-webkit-box",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textDecoration: "none",
                          marginLeft: "-2px",
                        }}
                      >
                        )
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Typography
                      className="about-playlist"
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "#00000080",
                      }}
                    >
                      Followers
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "#00ADB5",
                      }}
                    >
                      {playlistData?.playlist.totalFollower}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Typography
                      className="about-playlist"
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "#00000080",
                      }}
                    >
                      # of Tracks
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "#00ADB5",
                      }}
                    >
                      {playlistData?.playlist.totalTrack}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                className="margin-0-xsm"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "auto",
                  alignItems: "center",
                }}
              >
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      padding: "6px 9px",
                      color: "#00ADB5",
                      borderColor: "#00ADB5",
                      height: "auto",
                      fontSize: "10px",
                      fontWeight: "400",
                      marginBottom: "12px",
                      display: "flex",
                      gap: "3px",
                    }}
                    onClick={handleClickOpen}
                  >
                    <HtmlTooltip
                      onClose={handleTooltipClose}
                      open={open}
                      title={
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontFamily: themeFonts["Poppins-Regular"],
                            lineHeight: "19.5px",
                            textTransform: "capitalize",
                          }}
                        >
                          You have to select at least one genre to be added to
                          our playlist catalog!"
                        </Typography>
                      }
                      placement="top"
                      arrow
                    >
                      <Box>
                        <EditGenresIcon
                          style={{
                            paddingRight: "3px",
                            boxSizing: "content-box",
                          }}
                        />
                        Edit Genres
                      </Box>
                    </HtmlTooltip>
                  </Button>

                  {/* <Button
                    variant="outlined"
                    className="playlist-edit-btn"
                    sx={{
                      padding: "6px 11.7px",
                      color: "#00ADB5",
                      borderColor: "#00ADB5",
                      height: "auto",
                      fontSize: "10px",
                      fontWeight: "400",
                      display: "flex",
                      gap: "3px",
                    }}
                    onClick={handleClickEditGatesOpen}
                  >
                    <EditGatesIcon />
                    Edit Gates
                  </Button> */}
                </>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginTop: "16px" }}
          >
            <MuiLink
              component={Link}
              to={ROUTES.DASHBOARD}
              sx={{
                color: themeColors["#FFFFFF"],
                fontSize: "16px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
              Cancel
            </MuiLink>
            <Button
              variant="outlined"
              sx={{
                color: themeColors["#00ADB5"],
                fontFamily: themeFonts["Poppins-Regular"],
                borderColor: themeColors["#FFFFFF"],
                backgroundColor: themeColors["#FFFFFF"],
                height: "auto",
                marginLeft: "auto",
                padding: "8px 9px",
                display: "flex",
                gap: "4px",
                alignItems: "center",
                fontSize: "13px",
                "&:hover": {
                  background: themeColors["#FFFFFF"],
                  border: "1px solid #FFFFFF",
                },
              }}
              onClick={handleContinueButtonClick}
            >
              {errors ? (
                "Submit another playlist"
              ) : (
                <>
                  {isLoading || isFetching ? (
                    <CircularProgress
                      size={17}
                      sx={{
                        color: "inherit",
                        fontWeight: "900",
                      }}
                    />
                  ) : (
                    <>
                      <ThumbsUpIcon className="thumbupstyle" />
                      That’s Right ,Continue
                    </>
                  )}
                </>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
      <GenresData
        handleCloseData={handleCloseData}
        isOpen={isOpen}
        setSelectedGenres={setSelectedGenres}
        selectedGenres={selectedGenres}
      />
      <EditGatesDialog
        handleCloseData={handleClickEditGatesClose}
        isOpen={isEditGatesOpen}
        setPlatformType={setPlatformType}
        setSpotifyAccountLink={setSpotifyAccountLink}
        setSpotifyPlaylistLink={setSpotifyPlaylistLink}
        setSpotifySongLink={setSpotifySongLink}
        platformType={platformType}
        spotifyAccountLink={spotifyAccountLink}
        spotifyPlaylistLink={spotifyPlaylistLink}
        spotifySongLink={spotifySongLink}
      />
    </Grid>
  );
};
