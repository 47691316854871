import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  Link as MuiLink,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { themeColors, themeFonts } from "../../configs";
import { MoreInfoIcon, LessInfoIcon } from "../../svgs";
import {
  ApprovedIcon,
  DeclineIcon,
  LikedIcon,
  LikeIcon,
  PendingIcon,
  SpotifyGreenLogo,
} from "../../pngs";
import { SubmittedPlaylistPage } from "./submitted-playlist-page";
import { YourPlaylistPage } from "./your-playlist-page";
import { useGetTrackByIdQuery } from "../../components/apis/artistsApi";
import { ROUTES } from "../../components/consts/routes.consts";
import { Select, MenuItem, FormControl } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { format } from "date-fns";
// import { truncate } from "fs";
import {
  useUserFollowMutation,
  useUserUnFollowMutation,
} from "../../components/apis/followUaerAPi";
import { toast } from "react-toastify";
import { useSaveTrackMutation } from "../../components/apis/saveSongsApi";

export const Dashboard = () => {
  const isMiniScreen = useMediaQuery("(max-width:855px)");
  const isMidiScreen = useMediaQuery("(max-width:1024px)");
  const { value, curatorTab } = useOutletContext<any>();
  const userName = localStorage.getItem("userName");
  const id = localStorage.getItem("userId");
  const navigate = useNavigate();
  const { data, refetch } = useGetTrackByIdQuery<any>({
    userId: id,
  });

  const [state, setState] = useState<string | null>(null);

  const [dateOrder, setDateOrder] = useState("new-to-old");
  const [open, setOpen] = useState(false);
  const [sortedData, setSortedData] = useState(data);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMoreInfo = (itemId: any) => {
    setState((prevExpandedItemId) =>
      prevExpandedItemId === itemId ? null : itemId
    );
  };

  const sortData = (order: any) => {
    if (!data) {
      return null;
    }
    const sorted = [...data].sort((a, b) => {
      const dateA: any = new Date(a.createdAt);
      const dateB: any = new Date(b.createdAt);
      return order === "new-to-old" ? dateB - dateA : dateA - dateB;
    });
    setSortedData(sorted);
  };
  const handleChange = (event: any) => {
    const order = event.target.value;
    setDateOrder(order);
    sortData(order);
  };
  useEffect(() => {
    sortData(dateOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  // const truncateString = (str: any, num: any) => {
  //   if (str?.length <= num) {
  //     return str;
  //   }
  //   return str?.slice(0, num) + "...";
  // };
  const [saveTrack] = useSaveTrackMutation();
  const handleSaveSong = async (trackId: any) => {
    try {
      await saveTrack({
        trackId,
        action: "add",
      }).unwrap();
      refetch();
      toast.success("Added to Liked Songs");
    } catch (error: any) {
      toast.error("Failed");
    }
  };
  const handleRemoveSong = async (trackId: any) => {
    try {
      await saveTrack({
        trackId,
        action: "remove",
      }).unwrap();
      refetch();
      toast.success("Removed from Liked Songs");
    } catch (error: any) {
      toast.error("Failed");
    }
  };
  const [followUser] = useUserFollowMutation();
  const [unFollowUser] = useUserUnFollowMutation();
  const handleFollowCurator = async (userUrl: any, id: any) => {
    try {
      await followUser({
        userUrl,
        id,
      }).unwrap();
      refetch();
      toast.success("You've followed the curator!");
    } catch (error: any) {
      toast.error("Failed");
    }
  };
  const handleUnFollowCurator = async (userUrl: any, id: any) => {
    try {
      await unFollowUser({
        userUrl,
        id,
      }).unwrap();
      refetch();
      toast.success("You've unfollowed the curator!");
    } catch (error: any) {
      toast.error("Failed");
    }
  };
  return (
    <Grid
      container
      spacing={3}
      sx={{
        margin: "0px !important",
        flexDirection: "column",
        width: "100% !important",
        flexWrap: "nowrap !important",
      }}
    >
      <Box
        sx={{
          backgroundSize: "cover",
          minHeight: isMiniScreen ? "0px" : "900px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Box>
          <TabContext value={value}>
            <TabPanel
              value="artists"
              sx={{
                padding: "0 !important",
              }}
            >
              <>
                <Grid
                  container
                  spacing={3}
                  sx={{
                    margin: isMiniScreen
                      ? "30px 0px !important"
                      : "0px !important",
                    flexDirection: "column",
                    width: "100% !important",

                    padding: "0 15px",
                    alignContent: isMiniScreen ? "center" : "unset",
                  }}
                >
                  <Grid
                    item
                    xs={11}
                    sx={{
                      width: "100%",
                      padding: isMiniScreen
                        ? "0px !important"
                        : "32px 0px 0px 17px",
                    }}
                  >
                    <Box
                      className="flex-direction-column-xsm"
                      sx={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <Typography
                        className="font-23-sm"
                        sx={{
                          fontSize: "32px",
                          fontWeight: "600",
                          color: "#000",
                          textAlign: isMiniScreen ? "center" : "unset",
                        }}
                      >
                        Hello, {userName}
                      </Typography>

                      <Button
                        className=" margin-0"
                        onClick={() => {
                          navigate(ROUTES.SUBMIT_A_SONG);
                        }}
                        sx={{
                          marginLeft: "auto",
                          padding: "11px 20px",
                          backgroundColor: "#00ADB5",
                          color: "#fff",
                          borderRadius: "33px",
                          height: "auto",
                          "&:hover": {
                            background: " #00ADB5",
                          },
                        }}
                      >
                        Submit a song
                      </Button>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        marginTop: "24px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Your Submissions
                      </Typography>

                      <FormControl sx={{ marginLeft: "auto" }}>
                        <Select
                          labelId="date-order-label"
                          id="date-order"
                          value={dateOrder}
                          onChange={handleChange}
                          IconComponent={open ? ExpandLessIcon : ExpandMoreIcon}
                          onOpen={handleOpen}
                          onClose={handleClose}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                "& ul": {
                                  padding: "0px !important",
                                },
                                "& .Mui-selected": {
                                  backgroundColor: "white",
                                },
                              },
                            },
                          }}
                          sx={{
                            color: "#00000080",
                            fontFamily: themeFonts["Poppins-SemiBold"],
                            fontSize: "16px",
                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                              border: "0px",
                            },
                            "& .css-smr373-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "6px 10px",
                              },
                          }}
                        >
                          <MenuItem
                            value="new-to-old"
                            sx={{
                              color: "#00000099",
                              padding: "10px 30px",
                              backgroundColor: "#FFFFFF !important",
                              fontSize: "12px",
                              fontFamily: themeFonts["Poppins-Medium"],
                              borderBottom: "0.5px solid #C8C8C8 !important",
                              "&:hover": {
                                backgroundColor: "#00ADB5 !important",
                                color: "#FFFFFF !important",
                              },
                            }}
                          >
                            Date: New to Old
                          </MenuItem>
                          <MenuItem
                            value="old-to-new"
                            sx={{
                              color: "#00000099",
                              padding: "10px 30px",
                              backgroundColor: "#FFFFFF !important",
                              fontSize: "12px",
                              fontFamily: themeFonts["Poppins-Medium"],

                              "&:hover": {
                                backgroundColor: "#00ADB5 !important",
                                color: "#FFFFFF !important",
                              },
                            }}
                          >
                            Date: Old to New
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    {sortedData?.length !== 0 ? (
                      sortedData?.map((trackItem: any, index: any) => {
                        const totalCounts = trackItem.allPlaylist.reduce(
                          (acc: any, playlistItem: any) => {
                            acc[playlistItem.status] =
                              (acc[playlistItem.status] || 0) + 1;
                            return acc;
                          },
                          { approved: 0, pending: 0, declined: 0 }
                        );

                        const handleSubmitAgain = (trackId: any) => {
                          navigate(ROUTES.SUBMIT_A_SONG_PLAYLIST, {
                            state: { trackData: trackId },
                          });
                        };
                        return (
                          <Box
                            key={index}
                            sx={{
                              boxShadow: "4px 4px 4px 0px #0000001A",
                              padding: isMiniScreen
                                ? "0px"
                                : "16px 16px 0 16px",
                              margin: "32px 0px",
                              borderLeft: "1px solid #0000001A",
                              borderTop: "1px solid #0000001A",
                            }}
                          >
                            <Box
                              className="flex-direction-column-xsm"
                              sx={{
                                display: "flex",
                                gap: "16px",
                              }}
                            >
                              <Box
                                className="w-100-sm h-auto-sm"
                                sx={{
                                  width: "144px",
                                  height: "144px",
                                }}
                              >
                                <img
                                  src={trackItem.track.trackImageUrl}
                                  alt=""
                                  className="img-fluid"
                                />
                              </Box>
                              <Box
                                sx={{
                                  flex: "1",
                                  display: "flex",
                                  padding: isMiniScreen ? "0px 10px" : "unset",
                                  position: isMiniScreen ? "relative" : "",
                                }}
                              >
                                <Box
                                  sx={{
                                    flex: "1",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      height: "54px",
                                      width: "117px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={SpotifyGreenLogo}
                                      alt=""
                                      style={{
                                        width: "90px",
                                        height: "27px",
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      paddingLeft: "13.5px",
                                    }}
                                  >
                                    <Box
                                      className="flex-direction-column-sm align-items-start-sm"
                                      sx={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <MuiLink
                                        component={Link}
                                        to={trackItem?.track?.trackUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                          fontSize: "16px",
                                          color: "#000000",
                                          textTransform: "capitalize",
                                          fontFamily:
                                            themeFonts["Poppins-SemiBold"],
                                          display: "-webkit-box",
                                          WebkitLineClamp: "1",
                                          WebkitBoxOrient: "vertical",
                                          overflow: "hidden",
                                          textDecoration: "none",
                                          wordWrap: "break-word",
                                          maxWidth: "500px",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                      >
                                        {trackItem?.track?.trackName}
                                      </MuiLink>
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          color: "#00ADB5",
                                          textTransform: "capitalize",
                                          WebkitLineClamp: "2",
                                          WebkitBoxOrient: "vertical",
                                          overflow: "hidden",
                                          textDecoration: "none",
                                          marginRight: "-5px",
                                          display: "flex",
                                          gap: "3px",
                                        }}
                                      >
                                        ( By
                                        <MuiLink
                                          component={Link}
                                          to={
                                            trackItem?.track?.artistsArr[0]
                                              ?.artistUrl
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          sx={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            color: "#00ADB5",
                                            textTransform: "capitalize",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "2",
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            textDecoration: "none",
                                            "&:hover": {
                                              textDecoration: "underline",
                                            },
                                          }}
                                        >
                                          {trackItem.track.artistName}
                                        </MuiLink>
                                        )
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        marginTop: "12px",
                                        gap: "5px",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#00000080",
                                        }}
                                      >
                                        Latest Submission :
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#00000080",
                                        }}
                                      >
                                        {format(
                                          new Date(trackItem.createdAt),
                                          "dd/MM/yyyy"
                                        )}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: isMidiScreen
                                      ? "column"
                                      : "row",
                                    position: isMiniScreen ? "absolute" : "",
                                    right: isMiniScreen ? "10px" : "",
                                    top: isMiniScreen ? "13.5px" : "",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flexStart",
                                    }}
                                  >
                                    <Button
                                      onClick={() =>
                                        trackItem.isSaved === false
                                          ? handleSaveSong(
                                              trackItem.track.trackId
                                            )
                                          : handleRemoveSong(
                                              trackItem.track.trackId
                                            )
                                      }
                                      sx={{
                                        marginLeft: "auto",
                                        backgroundColor: "black",
                                        color: "#FFFFFF",
                                        borderRadius: "33px",
                                        fontSize: isMiniScreen
                                          ? "10px"
                                          : "12px",
                                        height: isMiniScreen ? "25px" : "50px",
                                        width: "70px",
                                        fontFamily: themeFonts["Poppins-Bold"],
                                        // border: "1px solid #00ADB5",
                                      }}
                                      variant="contained"
                                    >
                                      {trackItem.isSaved === false ? (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <img
                                            src={LikeIcon}
                                            alt="Like"
                                            height={"20px"}
                                          />
                                          <Typography
                                            sx={{
                                              lineHeight: "10px",
                                              fontSize: "10px",
                                              fontFamily:
                                                themeFonts["Poppins-Bold"],
                                            }}
                                          >
                                            LIKE
                                          </Typography>
                                        </Box>
                                      ) : (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <img
                                            src={LikedIcon}
                                            alt="LikedIcon"
                                            height={"20px"}
                                          />
                                          <Typography
                                            sx={{
                                              lineHeight: "10px",
                                              fontSize: "10px",
                                              fontFamily:
                                                themeFonts["Poppins-Bold"],
                                            }}
                                          >
                                            LIKED
                                          </Typography>
                                        </Box>
                                      )}
                                    </Button>
                                  </Box>
                                  <Box>
                                    <Button
                                      variant="contained"
                                      onClick={() =>
                                        handleSubmitAgain(
                                          trackItem.track.trackId
                                        )
                                      }
                                      sx={{
                                        marginLeft: "auto",
                                        backgroundColor: "#00ADB5",
                                        color: "#fff",
                                        borderRadius: "33px",
                                        height: "auto",
                                        padding: isMiniScreen
                                          ? "4px 13px"
                                          : "11px 26px",
                                        fontSize: isMiniScreen
                                          ? "10px"
                                          : "16px",
                                        fontFamily: themeFonts["Poppins-Bold"],
                                      }}
                                    >
                                      Submit Again
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Box
                              className="flex-direction-column-xsm"
                              sx={{
                                borderTop: "1px solid  #00000080",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "16px",
                              }}
                            >
                              <Box
                                className="border-bottom-sm w-100-sm text-align-center-sm"
                                sx={{
                                  flex: "1",
                                  borderRight: "1px solid #00000080",
                                  padding: "3px",
                                }}
                              >
                                <Button
                                  variant="text"
                                  endIcon={
                                    state === trackItem._id ? (
                                      <LessInfoIcon />
                                    ) : (
                                      <MoreInfoIcon />
                                    )
                                  }
                                  sx={{
                                    fontFamily: themeFonts["Poppins-SemiBold"],
                                    fontSize: "16px",
                                    color: themeColors["#00ADB5"],
                                  }}
                                  onClick={() => handleMoreInfo(trackItem._id)}
                                >
                                  {state === trackItem._id
                                    ? "Less Info"
                                    : "More Info"}
                                </Button>
                              </Box>
                              <Box
                                className="border-bottom-sm w-100-sm justify-content-center-sm"
                                sx={{
                                  borderRight: "1px solid #00000080",
                                  padding: "16px",
                                  display: "flex",
                                  gap: "16px",
                                  width: "138px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#00000080",
                                  }}
                                >
                                  Approved
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    color: "#000000",
                                  }}
                                >
                                  {totalCounts.approved}
                                </Typography>
                              </Box>
                              <Box
                                className="border-bottom-sm w-100-sm justify-content-center-sm"
                                sx={{
                                  padding: "16px ",
                                  borderRight: "1px solid #00000080",
                                  display: "flex",
                                  gap: "16px",
                                  width: "138px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#00000080",
                                  }}
                                >
                                  Pending
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    color: "#000000",
                                  }}
                                >
                                  {totalCounts.pending}
                                </Typography>
                              </Box>
                              <Box
                                className="border-bottom-sm w-100-sm justify-content-center-sm"
                                sx={{
                                  padding: "16px ",
                                  display: "flex",
                                  gap: "16px",
                                  width: "138px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#00000080",
                                  }}
                                >
                                  Declined
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    color: "#000000",
                                  }}
                                >
                                  {totalCounts.declined}
                                </Typography>
                              </Box>
                            </Box>
                            {state === trackItem._id &&
                              trackItem.allPlaylist.map(
                                (playlistItem: any, index: any) => {
                                  return (
                                    <Box
                                      className="flex-direction-column-xsm"
                                      key={index}
                                      sx={{
                                        borderTop: "1px solid  #00000080",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        className="border-bottom-sm w-100-sm text-align-center-sm"
                                        sx={{
                                          flex: "1",
                                          borderRight: "1px solid #00000080",
                                          padding: "3px",
                                          height: "108px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box
                                          className="flex-direction-column-xsm"
                                          sx={{
                                            display: "flex",
                                            gap: "16px",
                                            width: "100%",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Box
                                            className=""
                                            sx={{
                                              width: isMiniScreen
                                                ? "100%"
                                                : "76px",
                                              maxWidth: "800px",
                                              aspectRatio: "1 / 1",
                                              background: `url(${playlistItem?.playlist?.imageUrl})`,
                                              backgroundSize: "cover",
                                              backgroundPosition: "center",
                                              backgroundRepeat: "no-repeat",
                                            }}
                                          ></Box>
                                          <Box
                                            sx={{
                                              flex: "1",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                flex: "1",
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  height: "54px",
                                                  width: "117px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  marginTop: "-10px",
                                                }}
                                              >
                                                <img
                                                  src={SpotifyGreenLogo}
                                                  alt=""
                                                  style={{
                                                    width: "90px",
                                                    height: "27px",
                                                  }}
                                                />
                                              </Box>
                                              <Box
                                                sx={{
                                                  paddingLeft: "13.5px",
                                                }}
                                              >
                                                <Box
                                                  className="flex-direction-column-sm align-items-start-sm"
                                                  sx={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <MuiLink
                                                    component={Link}
                                                    to={
                                                      playlistItem.playlist
                                                        ?.playlistUrl
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{
                                                      fontSize: "16px",
                                                      color: "#000000",
                                                      fontFamily:
                                                        themeFonts[
                                                          "Poppins-SemiBold"
                                                        ],
                                                      display: "-webkit-box",
                                                      WebkitLineClamp: "2",
                                                      WebkitBoxOrient:
                                                        "vertical",
                                                      overflow: "hidden",
                                                      textDecoration: "none",
                                                      wordWrap: "break-word",
                                                      "&:hover": {
                                                        textDecoration:
                                                          "underline",
                                                      },
                                                      lineHeight: "19px",
                                                    }}
                                                  >
                                                    {
                                                      playlistItem.playlist
                                                        ?.playlistName
                                                    }
                                                  </MuiLink>
                                                  {/* <Typography
                                                  sx={{
                                                    fontSize: "10px",
                                                    fontWeight: "500",
                                                    color: "#00ADB5",
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  ( By{" "}
                                                  {
                                                    playlistItem.playlist
                                                      ?.ownerName
                                                  }
                                                  )
                                                </Typography> */}
                                                  <Typography
                                                    sx={{
                                                      fontSize: "12px",
                                                      fontWeight: "500",
                                                      color: "#00ADB5",
                                                      textTransform:
                                                        "capitalize",
                                                      WebkitLineClamp: "2",
                                                      WebkitBoxOrient:
                                                        "vertical",
                                                      overflow: "hidden",
                                                      textDecoration: "none",
                                                      marginRight: "-5px",
                                                      display: "flex",
                                                      gap: "3px",
                                                    }}
                                                  >
                                                    ( By
                                                    <MuiLink
                                                      component={Link}
                                                      to={
                                                        playlistItem.playlist
                                                          ?.userId?.profileUrl
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "500",
                                                        color: "#00ADB5",
                                                        textTransform:
                                                          "capitalize",
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: "2",
                                                        WebkitBoxOrient:
                                                          "vertical",
                                                        overflow: "hidden",
                                                        textDecoration: "none",
                                                        "&:hover": {
                                                          textDecoration:
                                                            "underline",
                                                        },
                                                      }}
                                                    >
                                                      {
                                                        playlistItem.playlist
                                                          ?.ownerName
                                                      }
                                                    </MuiLink>
                                                    )
                                                  </Typography>
                                                </Box>

                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    gap: "8px",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      color: "#00000080",
                                                    }}
                                                  >
                                                    Submitted On :{" "}
                                                    {format(
                                                      new Date(
                                                        playlistItem?.createdAt
                                                      ),
                                                      "dd/MM/yyyy"
                                                    )}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            </Box>
                                            <Box
                                              sx={{
                                                paddingRight: "16px",
                                              }}
                                            >
                                              {playlistItem?.isFollowingOwner ===
                                              false ? (
                                                <Button
                                                  onClick={() =>
                                                    handleFollowCurator(
                                                      playlistItem?.playlist
                                                        ?.userId?.profileUrl,
                                                      playlistItem?.playlist
                                                        ?.playlistOwnerId
                                                    )
                                                  }
                                                  sx={{
                                                    marginLeft: "auto",
                                                    backgroundColor: "black",
                                                    color: "#FFFFFF",
                                                    borderRadius: "33px",
                                                    fontSize: isMiniScreen
                                                      ? "10px"
                                                      : "12px",
                                                    height: isMiniScreen
                                                      ? "25px"
                                                      : "50px",
                                                    width: "140px",
                                                    fontFamily:
                                                      themeFonts[
                                                        "Poppins-Bold"
                                                      ],
                                                    // border: "1px solid #00ADB5",
                                                  }}
                                                  variant="contained"
                                                >
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                      gap: "5px",
                                                    }}
                                                  >
                                                    <img
                                                      src={LikeIcon}
                                                      alt="Like"
                                                      height={"20px"}
                                                    />
                                                    <Typography
                                                      sx={{
                                                        lineHeight: "10px",
                                                        fontSize: "10px",
                                                        fontFamily:
                                                          themeFonts[
                                                            "Poppins-Bold"
                                                          ],
                                                      }}
                                                    >
                                                      Follow Curator
                                                    </Typography>
                                                  </Box>
                                                </Button>
                                              ) : (
                                                <Button
                                                  onClick={() =>
                                                    handleUnFollowCurator(
                                                      playlistItem?.playlist
                                                        ?.userId?.profileUrl,
                                                      playlistItem?.playlist
                                                        ?.playlistOwnerId
                                                    )
                                                  }
                                                  sx={{
                                                    marginLeft: "auto",
                                                    backgroundColor: "black",
                                                    color: "#FFFFFF",
                                                    borderRadius: "33px",
                                                    fontSize: isMiniScreen
                                                      ? "10px"
                                                      : "12px",
                                                    height: isMiniScreen
                                                      ? "25px"
                                                      : "50px",
                                                    width: "140px",
                                                    fontFamily:
                                                      themeFonts[
                                                        "Poppins-Bold"
                                                      ],
                                                    // border: "1px solid #00ADB5",
                                                  }}
                                                  variant="contained"
                                                >
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                      gap: "5px",
                                                    }}
                                                  >
                                                    <img
                                                      src={LikedIcon}
                                                      alt="Like"
                                                      height={"20px"}
                                                    />
                                                    <Typography
                                                      sx={{
                                                        lineHeight: "10px",
                                                        fontSize: "10px",
                                                        fontFamily:
                                                          themeFonts[
                                                            "Poppins-Bold"
                                                          ],
                                                      }}
                                                    >
                                                      Following
                                                    </Typography>
                                                  </Box>
                                                </Button>
                                                // <Button
                                                //   onClick={() =>
                                                //     handleUnFollowCurator(
                                                //       playlistItem?.playlist
                                                //         ?.userId?.profileUrl,
                                                //       playlistItem?.playlist
                                                //         ?.playlistOwnerId
                                                //     )
                                                //   }
                                                //   sx={{
                                                //     marginLeft: "auto",
                                                //     backgroundColor: "#FFFFFF",
                                                //     color: "#00ADB5",
                                                //     borderRadius: "33px",
                                                //     height: "auto",
                                                //     padding: "4px 13px",
                                                //     fontSize: isMiniScreen
                                                //       ? "10px"
                                                //       : "14px",
                                                //     fontFamily:
                                                //       themeFonts[
                                                //         "Poppins-Bold"
                                                //       ],
                                                //     border: "1px solid #00ADB5",
                                                //     width: "140px",
                                                //     "&:hover": {
                                                //       backgroundColor:
                                                //         "#A8CDF142",
                                                //       color: "#00ADB5",
                                                //     },
                                                //   }}
                                                // >
                                                //   Following
                                                // </Button>
                                              )}
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>

                                      <Box
                                        className="border-bottom-sm w-100-sm justify-content-center-sm"
                                        sx={{
                                          borderRight: "1px solid #00000080",
                                          padding: "16px",
                                          display: "flex",
                                          gap: "16px",
                                          height: "108px",
                                          alignItems: "center",
                                          width: "138px",
                                        }}
                                      >
                                        {playlistItem.status === "approved" ? (
                                          <Typography
                                            sx={{
                                              fontSize: "16px",
                                              fontWeight: "400",
                                              color: "#00000080",
                                              display: "flex",
                                              gap: "4px",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={ApprovedIcon}
                                              alt="ApprovedIcon"
                                            />
                                            Approved
                                          </Typography>
                                        ) : (
                                          ""
                                        )}
                                      </Box>

                                      <Box
                                        className="border-bottom-sm w-100-sm justify-content-center-sm"
                                        sx={{
                                          borderRight: "1px solid #00000080",
                                          padding: "16px ",
                                          display: "flex",
                                          gap: "16px",
                                          height: "108px",
                                          alignItems: "center",
                                          width: "138px",
                                        }}
                                      >
                                        {playlistItem.status === "pending" ? (
                                          <Typography
                                            sx={{
                                              fontSize: "16px",
                                              fontWeight: "400",
                                              color: "#00000080",
                                              display: "flex",
                                              gap: "4px",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={PendingIcon}
                                              alt="PendingIcon"
                                            />{" "}
                                            Pending
                                          </Typography>
                                        ) : (
                                          ""
                                        )}
                                      </Box>

                                      <Box
                                        className="border-bottom-sm w-100-sm justify-content-center-sm"
                                        sx={{
                                          padding: "16px ",
                                          display: "flex",
                                          gap: "16px",
                                          height: "108px",
                                          alignItems: "center",
                                          width: "138px",
                                        }}
                                      >
                                        {playlistItem.status === "declined" ? (
                                          <Typography
                                            sx={{
                                              fontSize: "16px",
                                              fontWeight: "400",
                                              color: "#00000080",
                                              display: "flex",
                                              gap: "4px",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={DeclineIcon}
                                              alt="DeclineIcon"
                                            />
                                            Declined
                                          </Typography>
                                        ) : (
                                          ""
                                        )}
                                      </Box>
                                    </Box>
                                  );
                                }
                              )}
                          </Box>
                        );
                      })
                    ) : (
                      <Box
                        sx={{
                          backgroundColor: "#F0F0F0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "33px",
                          textAlign: "center",
                          height: "330px",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "32px",
                              fontWeight: "600",
                              color: "#000000",
                            }}
                          >
                            0 Submissions
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#00000080",
                              marginTop: "8px",
                            }}
                          >
                            You haven’t submitted a song to playlist yet
                          </Typography>

                          <Button
                            variant="contained"
                            onClick={() => {
                              navigate(ROUTES.SUBMIT_A_SONG);
                            }}
                            sx={{
                              marginTop: "24px",
                              padding: "11px 20px",
                              backgroundColor: "#00ADB5",
                              color: "#fff",
                              borderRadius: "33px",
                              height: "auto",
                            }}
                          >
                            Submit a song
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </>
            </TabPanel>
            <TabPanel
              value="curators"
              sx={{
                padding: "0 !important",
              }}
            >
              <>
                <Grid
                  container
                  spacing={3}
                  sx={{
                    margin: "0px !important",
                    flexDirection: "column",
                    width: "100% !important",
                    padding: "0 15px",
                  }}
                >
                  <TabContext value={curatorTab}>
                    <TabPanel
                      value="submitted"
                      sx={{
                        padding: "0 !important",
                      }}
                    >
                      <SubmittedPlaylistPage />
                    </TabPanel>
                    <TabPanel
                      value="playlists"
                      sx={{
                        padding: "0 !important",
                      }}
                    >
                      <YourPlaylistPage />
                    </TabPanel>
                  </TabContext>
                </Grid>
              </>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Grid>
  );
};
