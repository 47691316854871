import { Box, Grid, Typography, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { OkIcon, CancelIcon } from "../../svgs";
import { useUpdatePlayListMutation } from "../../components/apis/playlist";
import { useGetTrackByIdQuery } from "../../components/apis/artistsApi";
import { differenceInDays, format } from "date-fns";
import { themeFonts } from "../../configs";
import { useGetDayCountQuery } from "../../components/apis/warningDaysApi";
import { SpotifyGreenLogo } from "../../pngs";

export const PlayListTrack = ({
  playlistId,
  trackItem,
  uri,
  refetch,
  userInfoRefetch,
  playlistItem,
}: {
  playlistId?: any;
  trackItem?: any;
  uri?: any;
  refetch?: any;
  userInfoRefetch?: any;
  playlistItem?: any;
}) => {
  const { data: dayCount } = useGetDayCountQuery();
  //  const dayValue = dayCount?.daysCount[0]?.noOfDays

  const dayValue =
    dayCount &&
    dayCount?.daysCount?.map((daysValue: any) => {
      return daysValue.noOfDays;
    });

  const [updatePlayListStatus] = useUpdatePlayListMutation();
  const id = localStorage.getItem("userId");
  const { refetch: trackRefetch } = useGetTrackByIdQuery<any>({
    userId: id,
  });
  const [approvedLoading, setApprovedLoading] = useState(false);
  const [declinedLoading, setDeclinedLoading] = useState(false);
  const handleApproved = async (trackId: any) => {
    setApprovedLoading(true);
    try {
      await updatePlayListStatus({
        playlistId: playlistId,
        status: "approved",
        uri: uri,
        declineReason: "",
        trackId: trackId,
      }).unwrap();
    } catch (error: any) {}
    refetch();
    trackRefetch();
    setTimeout(() => {
      userInfoRefetch();
    }, 1000);
  };

  const handleDecline = async (trackId: any) => {
    setDeclinedLoading(true);
    try {
      await updatePlayListStatus({
        playlistId: playlistId,
        status: "declined",
        uri: uri,
        declineReason: "",
        trackId: trackId,
      }).unwrap();
    } catch (error: any) {}
    refetch();
    trackRefetch();
    setTimeout(() => {
      userInfoRefetch();
    }, 1000);
  };

  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    if (trackItem && trackItem.createdAt) {
      const submissionDate = new Date(trackItem.createdAt);

      const dateOnly = submissionDate.toISOString().split("T")[0];
      const dateOnlySubmissionDate = new Date(dateOnly);

      const expirationDate = new Date(dateOnlySubmissionDate);
      expirationDate.setDate(dateOnlySubmissionDate.getDate() + 22);

      const currentDate = new Date();
      const daysLeft = differenceInDays(expirationDate, currentDate);
      setDaysLeft(daysLeft);
    }
  }, [trackItem]);

  return (
    <Grid
      sx={{
        maxWidth: "390px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          boxShadow: "4px 4px 4px 0px #0000001A",
          padding: "0 16px 12px 16px",
        }}
      >
        <Box
          sx={{
            margin: "auto",
            height: "54px",
            width: "117px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={SpotifyGreenLogo}
            alt=""
            style={{
              width: "90px",
              height: "27px",
            }}
          />
        </Box>
        <iframe
          src={`https://open.spotify.com/embed/track/${trackItem.track.trackId}?utm_source=generator&theme=0`}
          width={"100%"}
          height={"87px"}
          title="playlist"
          frameBorder={0}
          allowTransparency={true}
        ></iframe>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: "13px",
              color: "#00000080",
              margin: "16px 0",
            }}
          >
            Submitted on: {format(new Date(trackItem.createdAt), "dd/MM/yyyy")}
          </Typography>

          <Typography
            sx={{
              fontSize: "13px",
              color: "#00000080",
              margin: "16px 0",
            }}
          >
            Expires after {daysLeft} days
          </Typography>
        </Box>
        <Box
          className="flex-direction-column-xsm"
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            onClick={() => handleApproved(trackItem._id)}
            variant="contained"
            sx={{
              padding: "11px 45px",
              backgroundColor: "#00ADB5",
              borderRadius: "33px",
              color: "#fff",
              fontSize: "16px",
              height: "auto",
              display: "flex",
              gap: "5px",
              minWidth: "170px",
            }}
          >
            {approvedLoading ? (
              <CircularProgress
                size={17}
                sx={{
                  color: "inherit",
                  fontWeight: "900",
                }}
              />
            ) : (
              <>
                <OkIcon /> Accept
              </>
            )}
          </Button>
          <Button
            className="margin-0"
            onClick={() => handleDecline(trackItem._id)}
            variant="contained"
            sx={{
              padding: "11px 45px",
              backgroundColor: "#FF0000",
              borderRadius: "33px",
              color: "#fff",
              fontSize: "16px",
              height: "auto",
              marginLeft: "auto",
              display: "flex",
              gap: "5px",
              minWidth: "170px",
            }}
          >
            {declinedLoading ? (
              <CircularProgress
                size={17}
                sx={{
                  color: "inherit",
                  fontWeight: "900",
                }}
              />
            ) : (
              <>
                <CancelIcon />
                Decline
              </>
            )}
          </Button>
        </Box>

        <Typography
          sx={{
            fontSize: "10.7px",
            marginTop: "7px",
            color: "#00000080",
            fontFamily: themeFonts["Poppins-Medium"],
          }}
        >
          If{" "}
          <Box component="span" sx={{ color: "#00ADB5" }}>
            Accepted{" "}
          </Box>{" "}
          the song must stay in your playlist for at least{" "}
          <Box component="span" sx={{ color: "#00ADB5" }}>
            {dayValue} days!
          </Box>
        </Typography>
      </Box>
    </Grid>
  );
};
